<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-26 14:57:46
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-05 10:58:46
-->
<template>
  <div class="choose_cell">
    <a-modal title="选择小区" v-model="visible" :width="1200" style="top: 8px" :footer="null" :maskClosable="false">
      <a-row v-if="tableData.length > 0">
        <a-col :md="8" v-for="item in tableData" :key="item.index">
          <a-card
            :title="`【${item.province}】${item.contacts}`"
            class="my_card"
            @dblclick="toSubmit(item)"
          >
            <p>{{ item.contacts }} {{ item.phone }}</p>
            <p>{{ item.province }}{{ item.city }}{{ item.area }}{{ item.address }}</p>
          </a-card>
        </a-col>
      </a-row>
      <div v-else>暂无数据</div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'choose_cell',
  data() {
    return {
      visible: false,
      tableData: [],
    }
  },

  computed: {},

  created() {
  },

  methods: {
    getList(obj) {
      this.axios.get('/api/firm/community/dealerListData',obj).then((res) => {
        this.tableData = res.body.records
      })
      this.visible = true
    },

    toSubmit(row) {
      this.visible = false
      this.$emit('getData', row)
    }
  },
}
</script>

<style lang='scss' scoped>
.my_card {
  width: 95%;
  min-height: 180px;
  max-height: 180px;
  margin-bottom: 10px;
}
.active {
  border: 1px solid red;
}
</style>